import Config from '../../../../shared/Config';
import NetworkClient from '../Network';
import { beautifyUrl } from '../../../../utilities/common/Path';
import { NoCancellationReqType } from '../../../view/screens/Availability/types';
import {
  IAddProviderLeavesReqType,
  IAddProviderOverrideReqType,
  IRemoveProviderLeavesReqType,
} from '../../types/Availability';

const client = new NetworkClient();

export const getProviderMeetings = async (
  fromDate: string,
  toDate: string,
  timezone: string,
  status?: string[],
) => {
  const response = await client.doGet(
    Config.apiV2.availability.getMeetings,
    {
      timezone,
    },
    { from: fromDate, to: toDate, status },
  );
  if (response?.data) {
    return response?.data?.meetings;
  }

  throw new Error('Unable to fetch client meetings, please try again!');
};

export const getProviderMeetingDetail = async (meetingId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.availability.getMeetingDetail, [meetingId]),
  );
  if (response?.data) {
    return response?.data?.meeting;
  }

  throw new Error('Unable to fetch meeting details, please try again!');
};

export const cancelMeeting = async (
  meetingId: string,
  note: string,
  type?: string,
  otherReason?: string,
) => {
  const body = {
    options: {
      message: note,
    },
    reason: {
      type,
      otherReason,
    },
  };
  const response = await client.doPost(
    beautifyUrl(Config.api.sessions.cancelSession, [meetingId]),
    body,
  );
  if (response.error) {
    throw new Error(
      response.error?.message || 'Unable to cancel meeting, please try again!',
    );
  }

  return response.data;
};

export const notifyNoCancellation = async (body: NoCancellationReqType) => {
  const response = await client.doPost(
    Config.apiV2.sessions.cancellationFlows.noSessionCancellation,
    body,
  );
  if (response?.success) {
    return response;
  }
  throw new Error(
    response.data.error?.message ||
      'Unable to notify client, please try again!',
  );
};

export const getProviderAvailabilities = async () => {
  const response = await client.doGet(Config.apiV2.availability.availability);
  if (response?.data) {
    return response?.data;
  }

  throw new Error('Unable to fetch client availabilities, please try again!');
};

export const getProviderServicesAndClinics = async () => {
  const response = await client.doGet(
    Config.apiV2.availability.getServicesAndClinics,
  );
  if (response?.data) {
    return response?.data;
  }

  throw new Error('Unable to fetch services & clinics, please try again!');
};

export const getProviderOverrides = async (
  fromDate: string,
  toDate: string,
) => {
  const response = await client.doGet(
    Config.apiV2.availability.getOverrides,
    {},
    { from: fromDate, to: toDate },
  );
  if (response?.data) {
    return response?.data;
  }

  throw new Error('Unable to fetch overrides, please try again!');
};

export const addProviderOverride = async (payload: {
  overrides: IAddProviderOverrideReqType[];
}) => {
  const response = await client.doPost(
    Config.apiV2.availability.addOverride,
    payload,
  );
  if (response?.data) {
    return response?.data;
  }

  throw new Error('Unable to add override, please try again!');
};

export const removeProviderLeaves = async (
  payload: IRemoveProviderLeavesReqType,
) => {
  const response = await client.doPut(
    Config.apiV2.availability.removeLeaves,
    payload,
  );
  if (response?.data) {
    return response?.data;
  }

  throw new Error('Unable to remove leaves, please try again!');
};

export const addProviderLeaves = async (payload: IAddProviderLeavesReqType) => {
  const response = await client.doPost(
    Config.apiV2.availability.addLeaves,
    payload,
  );
  if (response?.data) {
    return response?.data;
  }

  throw new Error('Unable to add leaves, please try again!');
};
