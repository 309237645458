/* eslint-disable*/
import BaseClient from './BaseClient';
import {
  IConversationsMetadata,
  NewMessageType,
  SelectedUserMessagesType,
  IConversationPages,
  IOnUnreadCountPayload,
} from '../data-types/ClientTypes';
import {
  deleteMessage,
  getIrisMessages,
  sendIrisMessage,
} from '../services/API/Iris';
import {
  MessageChannelTypes,
  MessageSendType,
} from '../constants/CommonConstants';
import { IMessageSendType, IOnNewMessage, IOnUnreadCount } from '../data-types/ChatBrokerTypes';
import IrisSocket, { SocketMessage } from '../utils/socket';
import { IrisSocketEventsType } from '../constants/IrisConstants';
import { setChatMessagesConsumed } from '../services/API/CommonConversation';

export default class IrisClient extends BaseClient {
  private userMessages: SelectedUserMessagesType;

  private conversationList: IConversationPages;
  private onNewMessage: IOnNewMessage;

  private socket: IrisSocket | null;
  private onUnreadCount: IOnUnreadCount;

  constructor() {
    super();
    this.userMessages = {
      isNext: true,
      messages: [],
    } as SelectedUserMessagesType;
    this.conversationList = {
      isNext: true,
      clients: [],
    };
    this.onNewMessage = () => {};
    this.socket = null;
    this.setupSocket = this.setupSocket.bind(this);
    this.onUnreadCount = () => {};
  }

  setupSocket() {
    this.socket = new IrisSocket();
    this.socket.addSocketListener(
      IrisSocketEventsType.NEW_MESSAGE,
      (message: SocketMessage) => {
        const newMsg: NewMessageType = {
          createdAt: new Date(),
          id: message.id,
          senderId: message.senderId,
          message: message.message,
          attachments: message.attachments,
          deleted: false,
          conversationId: message.conversationId,
        };
        this.onNewMessage(newMsg);
      },
    );
    this.socket.addSocketListener(
      IrisSocketEventsType.UNREAD_COUNT,
      (data: IOnUnreadCountPayload) => {
        this.onUnreadCount(data);
      },
    )
  }

  onSocketNewMessage(callBack: IOnNewMessage) {
    this.onNewMessage = callBack;
  }

  onSocketUnreadCount(callBack: IOnUnreadCount){
    this.onUnreadCount = callBack;
  }

  async getConversationList(
    conversationList: IConversationsMetadata['userData'],
    page: number,
    totalPages: number,
  ): Promise<IConversationPages> {
    if (!this.socket) {
      this.setupSocket();
    }
    if (page === 1) {
      this.conversationList = {
        isNext: true,
        clients: [],
      };
    }
    const resp = conversationList?.map(
      (conversation: IConversationsMetadata['userData']['0']) => ({
        conversationId: conversation?.conversationId,
        unreadCount: conversation.unreadCount,
        lastMessageContent: conversation?.lastMessage?.message,
        lastMsgCreatedAt: new Date(conversation?.lastMessage?.createdAt),
        channelType: MessageChannelTypes.IRIS,
        attachmentType: conversation.lastMessage?.attachments?.[0]?.type,
        hasAppAccess: conversation?.hasAppAccess,
        deleted: conversation.lastMessage?.deleted,
        userId: conversation.userId,
        providerId: conversation.providerId,
        tags: conversation.tags,
        friendlyName: conversation.friendlyName,
        channelId: conversation.channelId,
        providerRole: conversation.providerRole,
      }),
    );
    this.conversationList.clients.push(...resp);
    this.conversationList.isNext = page < totalPages;
    return this.conversationList;
  }

  // fetch messages of selected Iris user

  async getSelectedUserMessages(
    conversationId: string,
    page: number,
  ): Promise<SelectedUserMessagesType> {
    if (page === 1) {
      this.userMessages = {
        isNext: true,
        messages: [],
      } as SelectedUserMessagesType;
    }
    if (!this.userMessages.isNext) {
      return this.userMessages;
    }

    const resp = await getIrisMessages(
      this.apiClient,
      conversationId,
      page.toString(),
    );
    this.userMessages.messages.push(...resp.data);
    this.userMessages.isNext = resp.data.length >= 10; // work around for not having is next key in api
    return this.userMessages;
  }

  // sending Iris messages

  async sendMessage(
    contentToSend: IMessageSendType,
    conversationId: string,
  ): Promise<string> {
    const formData = new FormData();
    if (contentToSend.type === MessageSendType.TEXT) {
      formData.append('message', contentToSend.data);
    } else if (contentToSend.type === MessageSendType.AUDIO) {
      const contentType = 'audio/x-wav';
      const blob = new Blob([contentToSend.data], { type: contentType });
      formData.append('message', contentToSend?.body ?? '');
      formData.append('attachments', blob);
    } else if (contentToSend.type === MessageSendType.IMAGE) {
      formData.append('message', contentToSend?.body ?? '');
      formData.append('attachments', contentToSend.data);
    } else if (contentToSend.type === MessageSendType.DEEPLINK) {
      formData.append(
        'message',
        `${contentToSend.data as string} ${contentToSend?.deepLink ?? ''}`,
      );
    } else if (contentToSend.type === MessageSendType.PDF) {
      formData.append('message', contentToSend?.body ?? '');
      formData.append('attachments', contentToSend.data);
    }
    const resp = await sendIrisMessage(this.apiClient, {
      data: formData,
      conversationId,
    });
    return resp;
  }
  async updateMessageConsumptionStatus(
    conversationId: string,
  ): Promise<boolean> {
    const resp = await setChatMessagesConsumed(conversationId);
    return resp.success;
  }

  async deleteSelectedMessage(
    conversationId: string,
    messageId: string,
  ): Promise<boolean> {
    const resp = await deleteMessage(this.apiClient, conversationId, messageId);
    if (resp) {
      this.userMessages.messages = this.userMessages.messages.map((message) => {
        if (message.id == messageId) {
          return { ...message, deleted: true };
        } else {
          return message;
        }
      });
    }
    return resp;
  }
}
