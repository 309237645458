import * as React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import ChatBroker from './ChatBroker';
import useAuth from '../../utilities/hooks/useAuth';
import { MessageChannelTypes } from '../constants/CommonConstants';
import {
  IDeleteMessageType,
  IMessageSendType,
} from '../data-types/ChatBrokerTypes';
import { newMessageNotificationSound } from '../../utilities/common/Audio';
import { useConversationRequest } from '../context/ConversationRequestContext';

const ChatBrokerRef = new ChatBroker();

export function useChatBroker(onSuccess?: () => void) {
  const { isLoggedIn, user } = useAuth();
  const { conversationRequestBody, setConversationRequestBody } =
    useConversationRequest();
  const { t } = useTranslation();

  const isNextMessageLoaded = React.useRef(false);
  const isNextChatLoaded = React.useRef(false);
  const {
    isLoading,
    data,
    refetch: refetchLatestConversation,
  } = useQuery(
    ['conversationListManipulated', JSON.stringify(conversationRequestBody)],
    () => ChatBrokerRef.getAllConversations(conversationRequestBody),
    {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      onSuccess() {
        isNextChatLoaded.current = true;
      },
    },
  );

  const { mutate: updateMsgConsumptionStatus } = useMutation(
    ({
      userId,
      channelType,
    }: {
      userId: string;
      channelType: MessageChannelTypes;
    }) => ChatBrokerRef?.updateMessageConsumptionStatus(userId, channelType),
    {
      onSuccess: () => {
        refetchLatestConversation();
      },
    },
  );

  const { mutate: sendMessage } = useMutation(
    ({
      contentToSend,
      conversationId,
      channelType,
    }: {
      contentToSend: IMessageSendType;
      conversationId: string;
      channelType: MessageChannelTypes;
    }) => ChatBrokerRef.sendMessage(contentToSend, conversationId, channelType),
    {
      onSuccess,
      onError: () => {
        toast.error(t('ERROR_STATE_INTERNET_HEADER'), {
          description: t('ERROR_STATE_INTERNET_SUBTEXT'),
        });
      },
    },
  );

  // fetches individual user messages

  const { mutate: getSelectedUserMessages, isLoading: loading } = useMutation(
    ({
      selectedConversationId,
      channelId,
      pageNo,
      channelType,
      refetch,
    }: {
      selectedConversationId: string;
      channelId: string;
      pageNo: number;
      channelType: MessageChannelTypes;
      refetch?: boolean;
    }) =>
      ChatBrokerRef.getSelectedUserMessages(
        selectedConversationId,
        channelId,
        pageNo,
        channelType,
        refetch,
      ),
    {
      onSuccess: (_, variables) => {
        if (!variables?.refetch) isNextMessageLoaded.current = true;
      },
    },
  );

  const { mutate: deleteMessage, isLoading: isdeleting } = useMutation(
    ({ conversationId, messageId, channelType }: IDeleteMessageType) =>
      ChatBrokerRef.deleteSelectedMessage(
        conversationId,
        messageId,
        channelType,
      ),
    {
      onSuccess: (_, input) => {
        getSelectedUserMessages({
          selectedConversationId: input.conversationId,
          channelId: '',
          channelType: input.channelType,
          pageNo: 1,
          refetch: true,
        });
        toast.success(t('DELETE_SUCCESS_PROMPT'));
      },
      onError: () => {
        toast.error(t('DELETE_MESSAGE_UNSUCCESSFUL'));
      },
    },
  );
  React.useEffect(() => {
    if (isLoggedIn) {
      ChatBrokerRef.onSocketNewMessage((message) => {
        refetchLatestConversation();
        if (
          !(
            message.senderId.toString() === 'system' ||
            message.senderId.toString() === user.id
          )
        ) {
          newMessageNotificationSound.play();
        }
      });
      ChatBrokerRef.onSocketUnreadCount(() => {
        setConversationRequestBody({ ...conversationRequestBody, page: 1 });
      });
    }
  }, [user, isLoggedIn]);

  return {
    allConversationList: data,
    isLoading,
    unreadCount: ChatBrokerRef.unreadCount,
    updateLatestConversation: setConversationRequestBody,
    getUserMetadataById: ChatBrokerRef.getUserMetadataByUserId,
    isNextChatLoaded,
    liveChatData: {
      getSelectedUserMessages,
      isLoading: loading,
      updateMsgConsumptionStatus,
      selectedUserData: ChatBrokerRef.userMessages,
      getSelectedUserUnreadCount: ChatBrokerRef.getSelectedUserUnreadCount,
      isNextMessageLoaded,
      isdeleting,
      deleteMessage,
    },
    sendMessage,
  };
}
